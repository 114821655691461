<template>
    <TaskAction :item="record" />
</template>

<script>
import TaskAction from '@apps/vue2TaskComponent/components/TaskActions/List.vue'

export default {
    props: {
        record: {
            type: Object,
            required: true
        }
    },
    components: {
        TaskAction
    }
}
</script>